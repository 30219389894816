export const environment = {
  production: false,
  baseUrl: 'https://devapi.strutoo.com/api/v1/',
  firebaseConfig: {
    apiKey: "AIzaSyC8YsKimmpx1dkkn_JpiUbAHLlPEJ0kRkc",
    authDomain: "strutoo-customer-android.firebaseapp.com",
    projectId: "strutoo-customer-android",
    storageBucket: "strutoo-customer-android.appspot.com",
    messagingSenderId: "381345392849",
    appId: "1:381345392849:web:bd69b4260281417cf43df0",
    measurementId: "G-RQC4QE4B5L"
  },
};

